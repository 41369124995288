// import { gsap } from "gsap";
import { formRegex } from "../_Common/const";


export default class ReqFormValidator {
    constructor(formBlockClass) {
        console.log(`ReqFormValidator class is loaded`);
        this.formBlock = document.querySelector(formBlockClass);
        // Form elements
        this.requestTeaser = this.formBlock.querySelector(`.request__teaser`);
        this.form = this.formBlock.querySelector(`.request__form`);
        this.formMain = this.formBlock.querySelector(`.request__form .request-form__main`);
        this.formSuccess = this.formBlock.querySelector(`.request__form .request-form__success`);
        this.formMistakesContainer = this.formBlock.querySelector(
            `.request__form .request-form__main .request-form__mistakes`
        );
        this.formMistakesMessage = this.formBlock.querySelector(`.request__form .request-form__mistakes .text-block`);
        this.formMistakesCloseBtn = this.formBlock.querySelector(
            `.jsFormMistakesCloseBtn`
        );
        this.nameInput = this.formBlock.querySelector(`.request__form .jsNameInput`);
        this.phoneInput = this.formBlock.querySelector(`.request__form .jsPhoneInput`);
        // this.mailInput = this.formBlock.querySelector(`.request__form .jsMailInput`);
        // this.commentText = this.formBlock.querySelector(`.request__form .jsCommentText`);
        this.titleBlock = this.formBlock.querySelector(`.request .request__title`);
        this.fieldsBlock = this.formBlock.querySelector(`.request__form .request-form__fields`);
        // this.formTitle = this.formBlock.querySelector(`.request .request__text`);
        this.submitBtnNode = this.formBlock.querySelector(`.request__form .request-form__submit`);
        this.responseStatusMessageNode = this.formBlock.querySelector(
            `.jsResponseStatus`
        );
        // Animation
        // this.mistakesTl = new gsap.timeline();
        // this.animationDuration = 0.15;
        // Event listeners
        if (this.nameInput) {
            this.nameInput.addEventListener(`input`, (e) => {
                this.handleNameInput(e);
            });
        }
        if (this.phoneInput) {
            this.phoneInput.addEventListener(`input`, (e) => {
                this.handlePhoneInput(e);
            });
        }
        if (this.mailInput) {
            this.mailInput.addEventListener(`input`, (e) => {
                this.handleMailInput(e);
            });
        }
        if (this.cityInput) {
            this.cityInput.addEventListener(`input`, (e) => {
                this.handleCityInput(e);
            });
        }
        if (this.form) {
            this.form.addEventListener(`submit`, (e) => {
                e.preventDefault();
                if(this.nameInput.value == '') {
                    this.nameInput.classList.add(`error`, `jsIsEmpty`);
                }
                if(this.phoneInput.value == '') {
                    this.phoneInput.classList.add(`error`, `jsIsEmpty`);
                }
                this.handleSubmit();
            });
        }

        // Kinda sorta state 😜
        this.nameIsValid = false;
        this.phoneIsValid = false;
        // this.mailIsValid = false;
        // this.cityIsValid = false;
    }

    // 🤩 🤩 🤩
    handleNameInput = (e) => {
        this.validateNameInput(e);
    };

    validateNameInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.nameInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.nameInput.classList.add(`jsIsEmpty`);
            this.nameIsValid = false;
        } else if (val.length > 1 && val.match(formRegex.nameRegex)) {
            this.nameInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.nameInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.nameIsValid = true;
        } else {
            this.nameInput.classList.remove(`success`, `jsSuccess`);
            this.nameInput.classList.add(`error`, `jsError`);
            this.nameIsValid = false;
        }
    };

    // 📱 📱 📱
    handlePhoneInput = (e) => {
        this.validatePhoneInput(e);
    };

    validatePhoneInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.phoneInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.phoneInput.classList.add(`jsIsEmpty`);
            this.phoneIsValid = false;
        } else if (
            val.length > 3
        ) {
            this.phoneInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.phoneInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.phoneIsValid = true;
        } else {
            this.phoneInput.classList.remove(`success`, `jsSuccess`);
            this.phoneInput.classList.add(`error`, `jsError`);
            this.phoneIsValid = false;
        }
    };

    // 💌 💌 💌
    // handleMailInput = (e) => {
    //    this.validateMailInput(e);
    // };

    validateMailInput = (e) => {
        this.handleMailErrorUpdate(e);
        const val = e.target.value;
        if (!val.length) {
            this.mailInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.mailInput.classList.add(`jsIsEmpty`);
            this.mailIsValid = false;
        } else if (val.length > 0 && val.match(formRegex.mailValidationRegex)) {
            this.mailInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.mailInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.mailIsValid = true;
        } else {
            this.mailInput.classList.remove(`success`, `jsSuccess`);
            this.mailInput.classList.add(`error`, `jsError`);
            this.mailIsValid = false;
        }
    };

    // handleMailErrorUpdate = (e) => {
    //     const parent = e.target.closest(`.form__item`);
    //     const errorDiv = parent.querySelector(`.form__item-mistake`);
    //     if (errorDiv) {
    //         errorDiv.innerText = this.handleEmailErrorMessage();
    //     }
    // };

    // // 🌇  🏙  🌉
    // handleCityInput = (e) => {
    //     this.validateCityInput(e);
    // };

    // validateCityInput = (e) => {
    //     const val = e.target.value;
    //     if (!val.length) {
    //         this.cityInput.classList.remove(
    //             `error`,
    //             `jsError`,
    //             `success`,
    //             `jsSuccess`
    //         );
    //         this.cityInput.classList.add(`jsIsEmpty`);
    //         this.cityIsValid = false;
    //     } else if (val.length >= 1) {
    //         this.cityInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
    //         this.cityInput.classList.add(`success`, `jsSuccess`);
    //         this.hideUserMistake(e);
    //         this.cityIsValid = true;
    //     } else {
    //         this.cityInput.classList.remove(`success`, `jsSuccess`);
    //         this.cityInput.classList.add(`error`, `jsError`);
    //         this.cityIsValid = false;
    //     }
    // };

    // Validate form
    validateForm = () => {
        if (
            this.nameIsValid &&
            this.phoneIsValid
        ) {
            return true;
        } else {
            this.hideAllMistakes();
            this.showUserMistakes();
            return false;
        }
    };

    showUserMistakes = () => {
        let invalidFields = this.formBlock.querySelectorAll(`.jsError, .jsIsEmpty`);
        invalidFields.forEach((el) => {
            let errorDiv = document.createElement("div");
            errorDiv.classList.add(
                `error`,
                `form__item-mistake`
            );
            let errorString = ``;
            if (el.classList.contains(`jsNameInput`)) {
                errorString = `Укажите Ваше имя`;
            } else if (el.classList.contains(`jsPhoneInput`)) {
                errorString = `Укажите Ваш телефон`;
            } 
            errorDiv.innerHTML = errorString;
            el.insertAdjacentElement(`afterend`, errorDiv);
        });
    };

    // handleEmailErrorMessage = () => {
    //     const mailInput = this.mailInput.value;
    //     if (mailInput.length === 0) return `Укажите Ваш E-mail`;
    //     const marker = mailInput.indexOf(`@`);
    //     if (marker === -1) return `E-mail должен содержать символ «@»`;
    //     const substrings = mailInput.split(`@`);
    //     if (substrings[0].length === 0)
    //         return `Введите часть e-mail до символа «@»`;
    //     if (substrings[1].length === 0)
    //         return `Введите часть e-mail после символа «@»`;
    //     return `Продолжайте ввод e-mail`;
    // };

    hideUserMistake = (e) => {
        const parent = e.target.closest(`.request-form__item`);
        const errorDiv = parent.querySelector(`.form__item-mistake`);
        if (errorDiv) errorDiv.remove();
    };

    hideAllMistakes = () => {
        const errorElements = this.formBlock.querySelectorAll(`.jsError, .jsIsEmpty`);

        errorElements.forEach((el) => {
            const parent = el.closest(`.request-form__item`);
            const errorDiv = parent.querySelector(`.form__item-mistake`);
            if (errorDiv) errorDiv.remove();
        });
    };

    handleSubmit = () => {
        if (this.validateForm()) {
            console.log(`Networking`);
            // this.sendLikeIts1979Again();
            this.sendViaFetch();
        }
    };
    

    sendViaFetch = async () => {
        console.log(`Sending via fetch`);
        $(this.formMistakesContainer).hide()
        const url = "/send-request/";
        const data = new FormData(document.querySelector('#sendRequestForm'));
        fetch("/send-mail-request/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            body: JSON.stringify(Object.fromEntries(data.entries()))
        })
            .then(response => response.json())
            .then(json => {
                $('div.request-form__mistakes').hide();
                $('div.request-form__mistakes div.text-block p').text('');
                $('input[name="name"').removeClass('error');
                $('input[name="phone"').removeClass('error');
    
                if(json.errors.length > 0){
                    let err = '';
                    Object.keys(json.errors).map(k => {
                        let f = Object.keys(json.errors[k]);
                        let e = $('input[name="'+f+'"');
                        err += json.errors[k][f] + ' ';
                        e.addClass('error');
                    });
                    $('div.request-form__mistakes').show();
                    $('div.request-form__mistakes div.text-block p').append(err);
                }else{
                    $('h2.request__title').hide();
                    $('div.request-form__fields').hide();
                    $('div.request-form__agreement').hide();
                    $('div.request-form__success').show();
    
                    document.cookie = "emailLandingForm=1;max-age=300;path=/;samesite=strict";
                }
    
            });

        if (response.ok) {
            console.log(`Response OK`);
            try {
                let responseJson = await response.json();
                this.handleResponse(responseJson);
            } catch (err) {
                console.error("Error parsing json from " + url + "\n" + err);
                this.showSubmitFail();
            }
        } else {
            console.error("http error while getting url: " + response.status);
            this.showSubmitFail();
        }
    };

    handleResponse = (responseJson) => {
        console.log(`Handling response`);
        if (responseJson.message == 'Success')
        {
            this.showSubmitSuccess();
        }
        else
        {
            this.showSubmitFail();
        }
    };


    showSubmitSuccess = () => {
        console.log(`Show submit success`);

        $(document).ready(function(){
            var f = this.form;
            f.addEventListener('submit', sendRequest);
        
            $(this.formSuccess).hide();
            $(this.formMistakesContainer).hide()
        });
    };

    showSubmitFail = () => {
        console.log(`Showing submit fail`);
        this.formMistakesMessage.innerHTML = '<p>Произошла ошибка</p>'
        $(this.formMistakesContainer).show()
    };
}
