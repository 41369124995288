function sendRequest(e) {
    e.preventDefault();

    if (document.cookie.indexOf('emailLandingForm') !== -1){
        $('div.request-form__mistakes').show();
        $('div.request-form__mistakes div.text-block p').text('Следующий запрос можно будет сделать через 5 минут');
        return;
    }

    const data = new FormData(e.target);  
    fetch("/send-mail-request/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-requested-with': 'XMLHttpRequest'
        },
        body: JSON.stringify(Object.fromEntries(data.entries()))
    })
        .then(response => response.json())
        .then(json => {
            $('div.request-form__mistakes').hide();
            $('div.request-form__mistakes div.text-block p').text('');
            $('input[name="name"').removeClass('error');
            $('input[name="phone"').removeClass('error');

            if(json.errors.length > 0){
                let err = '';
                Object.keys(json.errors).map(k => {
                    let f = Object.keys(json.errors[k]);
                    let e = $('input[name="'+f+'"');
                    err += json.errors[k][f] + ' ';
                    e.addClass('error');
                });
                $('div.request-form__mistakes').show();
                $('div.request-form__mistakes div.text-block p').append(err);
            }else{
                $('h2.request__title').hide();
                $('div.request-form__fields').hide();
                $('div.request-form__agreement').hide();
                $('div.request-form__success').show();

                document.cookie = "emailLandingForm=1;max-age=300;path=/;samesite=strict";
            }

        });
}

export default sendRequest;
