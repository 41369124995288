console.log("bundle is connected");

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Rellax from "rellax";
window.Rellax = Rellax;

import sendRequest from "../Request/Request.js";
window.sendRequest = sendRequest;

import ReqFormValidatorq from "../form/form";
window.ReqFormValidatorq = ReqFormValidatorq;
new ReqFormValidatorq('.request');




